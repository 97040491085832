
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';

function About() {

  return (
     <section className="resume-section d-flex d-column" id="about">
        <div className="my-auto">
          <h1 className="mb-0">But
            <span className="ps-3 last-name">Bui</span>
          </h1>
          <div className='subheading'>Java Developer</div>
          <div className="subheading mb-5 email">Texas 77479 ·&nbsp;
            <a href="mailto:butbui86@gmail.com">butbui86@gmail.com</a>
          </div>
          <p className="mb-5 h5">I am a passionate software engineer with 10+ years’ experience in web development and enterprise applications.
          <br/>Expertise in specification design, analytical skills, problem-solving, time management with track record of successful projects.
          <br/>Fast learner and collaborative team player, and detailed-oriented person.
          </p>
          <ul className="list-inline list-social-icons mb-0">
            <li className="list-inline-item">
              <a href="http://www.linkedin.com/in/butbui86">
                <FontAwesomeIcon icon={faLinkedin} size="4x" />
              </a>
            </li>
            <li className="list-inline-item ms-3">
              <a href="https://github.com/butbuiapp">
                <FontAwesomeIcon icon={faGithub} size="4x" />
              </a>
            </li>
          </ul>
        </div>
      </section>
  );
};

export default About;