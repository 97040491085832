import axios from 'axios';

const http = axios.create({
  baseURL: 'https://2d4o9t43m9.execute-api.us-east-1.amazonaws.com/dev',
  headers: { "Content-type": "application/json" }
});

class ContactService {
  saveContact = async (contact) => {
    try {
      const res = await http.post('/contacts', contact);
      return res;
    } catch (error) {
      return null;
    }
  }
}

const contactService = new ContactService();
export default contactService;