
function Skill() {
  return (
    <section className="resume-section d-flex flex-column" id="skills">
        <div className="my-auto">
          <h2 className="mb-3">Skills</h2>

          <ul>
            <li><span className="fw-bold">Programming Languages:</span>  Java, JavaScript</li>
            <li><span className="fw-bold">Platforms:</span>  Windows, Linux, Mac OS</li>
            <li><span className="fw-bold">SDLC:</span> Agile/Scrum, Waterfall, TDD, BDD</li>
            <li><span className="fw-bold">Web:</span>  React.js, JSP, Thymeleaf, HTML5, CSS3, DOM, Bootstrap, jQuery, Ajax, Redux, JSON, XML</li>
            <li><span className="fw-bold">Web Services:</span>  RESTful, SOAP</li>
            <li><span className="fw-bold">Web/App Servers:</span>  Apache/Tomcat, JBoss</li>
            <li><span className="fw-bold">Frameworks:</span>  Spring MVC, Spring Boot, Spring Data JPA, Spring Security, Spring Batch, 
            J2EE, EJB, Struts, Hibernate, JDBC, Microservices, Spring Cloud, Kafka, Node.js, Express.js</li>
            <li><span className="fw-bold">Design Patterns:</span> MVC, Singleton, Factory, Proxy, Observer</li>
            <li><span className="fw-bold">Cloud:</span> Strong knowledge of AWS (IAM, S3, EC2, Lambda, CloudWatch, Elastic Beanstalk)</li>
            <li><span className="fw-bold">Testing:</span> Unit testing, Automated Testing, Katalon, Selenium, JUnit, Mockito</li>
            <li><span className="fw-bold">Databases:</span>  Postgres, MySQL, Oracle, MS SQL, PL/SQL, Firebase</li>
            <li><span className="fw-bold">Tools:</span>  Maven, SVN, Git, Github, Jenkins, Docker, Jira, CI/CD, Confluence, Docker, Adobe XD</li>
          </ul>
          <h2 className="mb-3 pt-5">Certificates</h2>
          <ul>
            <li>ISTQB Foundation Level</li>
            <li>ISTQB Test Manager </li>
            <li>JLPT N2 – Japanese Certificate </li>
          </ul>
        </div>
      </section>
  )
}

export default Skill;