function Blogs() {
  return (
    <section className="resume-section d-flex flex-column" id="blogs">
        <div className="my-auto">
          <h2 className="mb-5">My Portfolio Architecture</h2>

          <div className="resume-item d-flex flex-column flex-md-row mb-5">
            <img src='img/architecture.png' alt="Website architecture"/>
          </div>

          <div>
            <h3>Upload React source code to S3 using AWS CLI</h3>
            <div>
              <h5>Step 1: Create a user and add permission access to S3 and Cloudfront to that user.</h5>
              <ul>
                <li>Go to IAM and click Create User.</li>
                <li>Add permissions: AmazonS3FullAccess, CloudFrontFullAccess.</li>
                <li>After the user is created, create access key for that user (Select Command Line Interface).</li>
                <li>Keep access key and secret access key to create a profile in the next step.</li>
              </ul>
            </div>
            <div>
              <h5>Step 2: Install AWS CLI in your computer and create a profile.</h5>
              <ul>
                <li>Go to https://docs.aws.amazon.com/cli/v1/userguide/cli-chap-install.html and download the install file.</li>
                <li>Verify if AWS CLI was installed by opening a terminal and run command: aws --version</li>
                <li>Run this command in a terminal to create a profile: aws configure --profile <strong>dev</strong>	
                  <br/>AWS Access Key ID [None]: enter the access key above			
                  <br/>AWS Secret Access Key [None]: enter the secret access key above			
                  <br/>Default region name [None]: enter a region (Ex: us-east-1)	
                  <br/>Default output format [None]: use default (just enter)
                </li>
                <li>Note: <strong>dev</strong> is the profile name.</li>
              </ul>
            </div>
            <div>
              <h5>Step 3: Add commands to scripts section of package.json file inside the React app.</h5>
              <div>
                <img src="img/package-json.png" alt="Scripts" />.
                <ul>
                  <li>The first command: npm run client-s3-deploy. It uploads files and folders inside build folder to a S3 bucket.</li>
                  <li>The second command: npm run client-cloudfront-invalidation. It clears cache in Cloudfront whenever there is an update on the S3 bucket.</li>
                  <li>The third command: npm run client-deploy. It is the combination of 3 commands 
                    (build the React app, upload to S3 and clear cache in Cloudfront)</li>
                </ul>
              </div>
            </div>
          </div>

        </div>
      </section>
  )
}

export default Blogs;