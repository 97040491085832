
function Education() {
  return (
      <section className="resume-section d-flex flex-column" id="education">
        <div className="my-auto">
          <h2 className="mb-5">Education</h2>

          <div className="resume-item d-flex flex-column flex-md-row mb-5">
            <div className="resume-content mr-auto">
              <h3 className="mb-0">Ho Chi Minh City University of Technology</h3>
              <div className="subheading mb-3">Bachelor's, Computer Engineering </div>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row">
            <div className="resume-content mr-auto">
              <h3 className="mb-0">Maharishi International University, Fairfield, Iowa</h3>
              <div className="subheading mb-3">Master's, Computer Science</div>
              <p>GPA: 3.95</p>
            </div>
            <div className="resume-date">
              <span className="time-period h5">May 2023 - December 2025</span>
            </div>
          </div>

        </div>
      </section>
  );
}

export default Education;
