
function Experience() {

  return (
      <section className="resume-section d-flex flex-column" id="experience">
        <div className="my-auto">
          <h2 className="mb-5zz">Experience</h2>

          <div className="resume-item d-flex flex-column flex-md-row mb-5">
            <div className="resume-content mr-auto">
              <h3 className="mb-0">Senior Software Engineer</h3>
              <div className="subheading mb-3">Comimex Japan</div>
              <ul className="h5">
                <li>Worked on all phases of SDLC including requirement analysis, design, implementation, testing, deployment, and support. </li>
                <li>Led in design, development, and management of the Purchase Management Application, overseeing product support and issue troubleshooting. 
                    Streamlined budget processes and significantly reduced time to purchase. </li>
                <li>Successfully migrated comprehensive functions/SQL/applications from Oracle to Postgres, reducing cost by 100%.</li>
              </ul>
            </div>
            <div className="resume-date text-md-right">
              <span className="time-period h5">April 2022 - March 2023</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row mb-5">
            <div className="resume-content mr-auto">
              <h3 className="mb-0">Technical Lead</h3>
              <div className="subheading mb-3">NTT Data Viet Nam</div>
              <ul className="h5">
                <li>Led code review to improve quality and integrity, resulting in 35% integration performance improvement. </li>
                <li>Designed, planned, and implemented automation testing for data migration using Java core, Katalon platform and Jenkins. 
                  Ensured data consistency by automatically testing hundreds of thousands of records within a span of 3 months. </li>
                <li>Led and implemented Restful APIs and an e-commerce full stack application.</li>
                <li>Researched and developed successful POC projects, resulting in achieving more opportunities from clients.</li>
                <li>Mentored team members by providing training on project requirements and conducting workshops, 
                  promoting team members to improve the overall Organization Capabilities.</li>
              </ul>
            </div>
            <div className="resume-date text-md-right">
              <span className="time-period h5">April 2019 - March 2022</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row mb-5">
            <div className="resume-content mr-auto">
              <h3 className="mb-0">Senior Software Engineer</h3>
              <div className="subheading mb-3">Comimex Japan</div>
                <ul className="h5">
                  <li>Developed a website managing products and stocks using Spring MVC, Hibernate and JSP.</li>
                  <li>Developed a consulting website using Java Liferay framework and Velocity and released on time in a very tight schedule.</li>
                  <li>Successfully converted applications from VB into VB.NET.</li>
                  <li>Implemented new functions to a system using Cobol, and developed a mobile application that allows customers to register new point card on Window8 and Android using PhoneGap.</li>
                </ul>
              </div>
            <div className="resume-date text-md-right">
              <span className="time-period h5">May 2014 - February 2017</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row mb-5">
            <div className="resume-content mr-auto">
              <h3 className="mb-0">Senior Software Engineer</h3>
              <div className="subheading mb-3">SMS Management & Technology</div>
                <ul className="h5">
                  <li>Developed an enterprise-wide banking workflow system for credit and debit card creation based on IBM FileNet System using Agile, Java, Dojo, BitBuckit, Rally. </li>
                  <li>Developed applications on mobile using PhoneGap, Antenna framework and Angularjs.</li>
                </ul>
              </div>
            <div className="resume-date text-md-right">
              <span className="time-period h5">September 2012 - April 2014</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row mb-5">
            <div className="resume-content mr-auto">
              <h3 className="mb-0">Senior Software Engineer</h3>
              <div className="subheading mb-3">Harvey Nash Viet Nam</div>
                <ul className="h5">
                  <li>Developed a website managing horse racing and horse name registration using Spring MVC, Java Web Service, PL/SQL, Jaxb, JSP, JQuery, Oracle, Tomcat, JBoss, Bugzilla. </li>
                  <li>Implemented new functions and maintained Purchase Card Reconciliation product which facilitates the process of reconciling statements for purchases using Java, JSP, EJB, JavaScript, Dojo, Oracle, SVN, CVS, Jira, JBoss.</li>
                </ul>
              </div>
            <div className="resume-date text-md-right">
              <span className="time-period h5">December 2010 - March 2012</span>
            </div>
          </div>

        </div>
      </section>
  );
}

export default Experience;